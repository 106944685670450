import React from 'react'
import Helmet from 'react-helmet'

const SEO = () => (
	<Helmet
		htmlAttributes={{
			metaLang: 'fr',
		}}
		title="Meily Admin Dashboard"
		meta={[
			{
				name: 'description',
				content: 'Meily Fabrics',
			},
			{
				property: 'og:title',
				content: 'Meily Admin Dashboard',
			},
			{
				property: 'og:description',
				content: 'Meily Admin Dashboard',
			},
			{
				property: 'og:type',
				content: 'website',
			},
			{
				name: 'viewport',
				content: 'minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no',
			},
		]}
	>
		<link rel="icon" type="image/png" href="/images/avatar.png" sizes="16x16" />
	</Helmet>
)

export default SEO
