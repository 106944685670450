import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { MuiThemeProvider, withStyles } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import Hidden from '@material-ui/core/Hidden'

import Navigator from 'components/Navigator'
import Header from 'components/Header'
import Seo from './Seo'
import theme from './theme'

const drawerWidth = 256

const styles = {
	root: {
		display: 'flex',
		minHeight: '100vh',
	},
	drawer: {
		[theme.breakpoints.up('sm')]: {
			width: drawerWidth,
			flexShrink: 0,
		},
	},
	appContent: {
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
	},
	mainContent: {
		flex: 1,
		padding: '0px',
	},
}

function Layout({ location, title, children, classes }) {
	const [mobileOpen, setMobileOpen] = useState(false)

	return (
		<MuiThemeProvider theme={theme}>
			<div className={classes.root}>
				<CssBaseline />
				<Seo />
				<nav className={classes.drawer}>
					<Hidden smUp implementation="js">
						<Navigator
							PaperProps={{ style: { width: drawerWidth } }}
							variant="temporary"
							open={mobileOpen}
							onClose={() => setMobileOpen(!mobileOpen)}
						/>
					</Hidden>
					<Hidden xsDown implementation="css">
						<Navigator PaperProps={{ style: { width: drawerWidth } }} location={location} />
					</Hidden>
				</nav>
				<div className={classes.appContent}>
					<Header onDrawerToggle={() => setMobileOpen(!mobileOpen)} title={title} />
					<main className={classes.mainContent}>{children}</main>
				</div>
			</div>
		</MuiThemeProvider>
	)
}

Layout.propTypes = {
	classes: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
	title: PropTypes.string.isRequired,
	children: PropTypes.object,
}
export default withStyles(styles)(Layout)
